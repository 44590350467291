import FrannieL from '../../images/contact/FrannieL.png'
import NelsonO from '../../images/contact/NelsonO.png'
import AkankshaS from '../../images/contact/AkankshaS.png'
import ElieC from '../../images/contact/ElieC.png'
import NatalieK from '../../images/contact_unedited/NatalieK.png'
import PH from '../../images/croppedvalues.png'
import SehEunH from '../../images/contact_unedited/SehEunH.png'
import PaineG from '../../images/contact/PaineG.png'
import JiWooB from '../../images/contact/JiWooB.png'
import JonY from '../../images/contact_unedited/JonY.png'
import AnantG from '../../images/contact/AnantG.png'
import CarolineH from '../../images/contact/CarolineH.png'
import LaraiahM from '../../images/contact_unedited/LaraiahM.png'
import ErikA from '../../images/contact_unedited/ErikA.png'
import TylerB from '../../images/contact_unedited/TylerB.png'
import DarianL from '../../images/contact_unedited/DarianL.png'
import NashR from '../../images/contact_unedited/NashR.png'
import MarkM from '../../images/contact_unedited/MarkM.png'
import SabrinaM from '../../images/contact_unedited/SabrinaM.png'

const connectData = [
  {
    position: "Interim Director",
    name: "Ji Woo Bae",
    major: "Information Science",
    team: "Operations",
    email: "mailto:jb2359@cornell.edu",
    linkedin: "https://www.linkedin.com/in/ji-woo-bae-2818371b4/",
    image: JiWooB
  },
  {
    position: "Interim Director",
    name: "Jonathan Yeung",
    major: "Architecture",
    team: "Operations",
    email: "mailto:jjy43@cornell.edu",
    linkedin: "https://www.linkedin.com/in/jonathan-yeung-a20828180/",
    image: JonY
  },
  {
    position: "Finance Chair",
    name: "Caroline He",
    major: "ORIE",
    team: "Operations",
    email: "mailto:rh463@cornell.edu",
    linkedin: "https://www.linkedin.com/in/allan-murciano-0561031a3/",
    image: CarolineH
  },
  {
    position: "New Member and Recruiting Chair",
    name: "Nash Rougvie",
    major: "Urban and Regional Studies",
    team: "Operations",
    email: "mailto:njr78@cornell.edu",
    linkedin: "https://www.linkedin.com/in/nash-rougvie-82626b201/",
    image: NashR
  },
  {
    position: "Team Lead",
    name: "Sophia Su",
    major: "Global Development",
    team: "Aquaculture",
    email: "mailto:sgs244@cornell.edu",
    linkedin: "https://www.linkedin.com",
    image: PH
  },
  {
    position: "Team Lead",
    name: "Arno Motulsky",
    major: "Information Science / Envr. & Sustainability",
    team: "Currents",
    email: "mailto:atm76@cornell.edu",
    linkedin: "https://www.linkedin.com",
    image: PH
  },
  {
    position: "Team Lead",
    name: "Erik Anderson",
    major: "Operations Research / Information Engineering",
    team: "ICN Modeling",
    email: "mailto:ewa32@cornell.edu",
    linkedin: "https://www.linkedin.com/in/erik-anderson-5b7735223",
    image: ErikA
  },
  {
    position: "Co-Team Lead",
    name: "Akanksha Srivastava",
    major: "Environmental Engineering",
    team: "ICN Policy",
    email: "mailto:as2752@cornell.edu",
    linkedin: "https://www.linkedin.com/in/akanksha-srivastava-385631160/",
    image: AkankshaS
  },
  {
    position: "Co-Team Lead",
    name: "Laraiah Maranan",
    major: "Urban and Regional Studies",
    team: "ICN Policy",
    email: "mailto:lgm46@cornell.edu",
    linkedin: "https://www.linkedin.com/in/laraiahm/",
    image: LaraiahM
  },
  {
    position: "Team Lead",
    name: "Tyler Bales",
    major: "Biology",
    team: "REAL",
    email: "mailto:trb223@cornell.edu",
    linkedin: "https://www.linkedin.com",
    image: TylerB
  },
  {
    position: "Team Lead",
    name: "Darian Lee",
    major: "Agricultural Sciences / Envr. & Sustainability",
    team: "Soil Factory",
    email: "mailto:dal329@cornell.edu",
    linkedin: "https://www.linkedin.com/in/darian-lee-2025",
    image: DarianL
  },
  {
    position: "Team Lead",
    name: "Anant Gupta",
    major: "Chemical Engineering",
    team: "Solar Panel Reboot",
    email: "mailto:akg73@cornell.edu",
    linkedin: "https://www.linkedin.com/in/anant-gupta-78200719/",
    image: AnantG
  },
  {
    position: "Team Lead",
    name: "Mark Minton",
    major: "City and Regional Planning",
    team: "Sustainable Education",
    email: "mailto:mjm757@cornell.edu",
    linkedin: "https://www.linkedin.com/in/mark-minton-449105207/",
    image: MarkM
  },
  {
    position: "Team Lead",
    name: "Sabrina Martin",
    major: "Information Science",
    team: "Sustainable Mobility: Garage",
    email: "mailto:smm436@cornell.edu",
    linkedin: "https://www.linkedin.com/in/sabrina-m-martin/",
    image: SabrinaM
  },
  {
    position: "Team Lead",
    name: "Paine Gronemeyer",
    major: "Urban and Regional Studies",
    team: "Sustainable Mobility: Shelter",
    email: "mailto:phg44@cornell.edu",
    linkedin: "https://www.linkedin.com/in/mia-millstein/",
    image: PaineG
  },
  {
    position: "Co-Team Lead",
    name: "Elie Cole",
    major: "Bio. Sciences / Envr. & Sustainability",
    team: "Sustainable Smithsonian",
    email: "mailto:mkc232@cornell.edu",
    linkedin: "https://www.linkedin.com/in/mariela-cole-556b181a9/",
    image: ElieC
  },
  {
    position: "Co-Team Lead",
    name: "Natalie Kelly",
    major: "Environment and Sustainability",
    team: "Sustainable Smithsonian",
    email: "mailto:nlk48@cornell.edu",
    linkedin: "https://www.linkedin.com/in/natalie-kelly-a291761ab/",
    image: NatalieK
  },
  {
    position: "Team Lead",
    name: "Nihar Hegde",
    major: "Computer Science / Physics",
    team: "Tech Ops",
    email: "mailto:nh336@cornell.edu",
    linkedin: "https://www.linkedin.com/in/nihar-hegde/",
    image: PH
  },
  {
    position: "Team Lead",
    name: "Seh Eun Emily Hong",
    major: "Design and Environmental Analysis",
    team: "MarComm",
    email: "mailto:sh846@cornell.edu",
    linkedin: "https://www.linkedin.com/in/seh-eun-emily-hong/",
    image: SehEunH
  },
];
export default connectData;